/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import Footer from "./footer"
import '../styles/index.scss'

import layoutStyles from './layout.module.scss'

const Layout = (props) => {
  return (
    <div>
      <Header />
      <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
          {props.children}
        </div>
        <Footer />
      </div>
    </div>

  )
}

export default Layout
